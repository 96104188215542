import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { pageVariants, pageTransition } from '../animations/animations';
import { motion } from 'framer-motion';

const HorizontalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  height: 100%;
  justify-content: right;
  // border: 2px solid rgba(255, 255, 255, 0.8);
  gap: 20px;
`;

// const HorizontalLayoutIcons = styled.div`
//   flex: 1 1 30%;
//   display: flex;
//   flex-direction: row;
//   justify-content: right;
//   gap: 20px;
//   width: 100%;
//   height: 100%;
// `;

const Container = styled.div`
  flex: 0 1 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
    height: 8px; /* Adjust height as needed */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px; /* Make it rounded */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: 30px; /* Minimum height for the vertical scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px; /* Minimum width for the horizontal scrollbar thumb */
  }

`;

// Function to convert hex color to rgba
function hexToRGBA(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

const TabContent = styled.div`
  flex: 1 1 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: rgba(230, 230, 230, 0.1);
  color: #000;
  margin-right: 10px;
  margin-bottom: 10px;

  &:hover {
    background-color: rgba(230, 230, 230, 0.1);
    background: radial-gradient(ellipse at top right,rgba(255, 255, 255, 0.4), ${props  => hexToRGBA(props.highlight_color, 0.4)} )

  }
`;

const SkillTag = styled.span`
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 12px;
  background-color: ${props => props.color};
  color: #fff;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  opacity: 0.9;
`;


const companies = [
  {
    svgPath: '/pg.svg',
    title: 'Programmatica',
    period: 'from Apr 2024',
    role: 'Golang Backend Engineer',
    skills: ['Golang', 'Postgres', 'Kafka', 'DDD', 'Rest API', 'Graphql', 'Nosql'],
    responsibilities: 'Assisted in developing backend services, wrote unit and integration tests, participated in code reviews...',
    achievement: 'Quickly ramped up on new technologies and contributed to key projects',
    highlight_color: '#6be1ff',
  },
  {
    svgPath: '/agio_03_logo.svg',
    title: 'Agioband',
    period: 'Jan 2023 - Dec 2023',
    role: 'Golang Backend Engineer',
    skills: ['Golang', 'DDD', 'EDD', 'Kubernetes', 'Cloud platform', 'S3', 'Postgres', 'Graphql', 'Nosql'], 
    responsibilities: 'Resolved critical backend bugs, enhanced Golang backend API code, created Python module to interact with Golang API...',
    achievement: 'Resolved critical bugs related to distributed transactions. Significantly improved API performance.',
    highlight_color: '#6be1ff',
  },
  {
    svgPath: '/platoshka.svg',
    title: 'Platoshka',
    period: 'Sep 2021 - Jan 2023',
    role: 'Team Lead Python Engineer',
    skills: ['Python', 'Animation Pipelines','Cloud platform', 'Agile','Scrum', 'Youtrack', 'Shotgrid' ],
    responsibilities: 'Oversaw computer graphics application development, introduced agile methodologies, managed team of 4 developers',
    achievement: 'Successfully implemented agile methodologies, improving team efficiency and incrementing project delivery speed.',
    highlight_color: '#306998',
  },
  {
    svgPath: '/Bazelevs_Company.svg',
    title: 'Bazelevs Company',
    period: 'Mar 2020 - Sep 2021',
    role: 'Python Engineer',
    skills: ['Python', 'Synchronization', 'Animation Pipelines', 'Docker', 'btrfs', 'S3'],
    responsibilities: 'Developed and maintained synchronization tools, managed data storage..',
    achievement: 'Prepared hardware for data storage, implemented synchronization tools, and improved data storage efficiency.',
    highlight_color: '#306998',
  },
  {
    svgPath: '/parovoz.svg',
    title: 'Parovoz',
    period: 'Aug 2019 - Sep 2021',
    role: 'Python Engineer',
    skills: ['Python', 'Docker', 'Celery', 'Webhooks', 'Afanasy', 'Shotgrid'],
    responsibilities: 'Specialized in building and managing pipelines for computer graphics work, developing applications and tools within Autodesk Shotgun framework...',
    achievement: 'Implemented a new pipeline for computer graphics work, improving efficiency and reducing errors.',
    highlight_color: '#306998',
  },
];

const skillColors = {
  'Golang': '#507C8C',
  'Kafka': '#3A3A3A',
  'DDD': '#B4503A',
  'EDD': '#3A7A7C',
  'Kubernetes': '#4A83B3',
  'Cloud platform': '#CCB96B',
  'S3': '#6A9B61',
  'Agile': '#CC7353',
  'Taiga': '#66B366',
  'Youtrack': '#5A4570',
  'Shotgrid': '#CC8040',
  'Docker': '#5E9AA8',
  'Celery': '#5A8068',
  'Webhooks': '#B45348',
  'Afanasy': '#4D6A85',
  'Postgres': '#577D9C',
  'Python': '#507A96',
  'Graphql': '#B35592',
  'Nosql': '#66A366',
  'Rest API': '#CC7353',
  'Animation Pipelines': '#CC8040',
  'Scrum': '#CCB96B',
  'btrfs': '#6A9B61',
  'Synchronization': '#3A7A7C',
};



// const SvgIcon = styled.div`
//   width: ${props => props.w};
//   height: ${props => props.h};
//   background: url(${props => props.svgPath}) no-repeat center / contain;
//   cursor: pointer;
//   transition: background 0.3s ease, background-color 0.3s ease;
//   filter: ${props => (props.active ? 'none' : '0.7')};
//   opacity: ${props => (props.active ? '1' : '0.7')};
//   &:hover {
//     opacity: ${props => (props.active ? '1' : '1.0')};
//   }
// `;

// const SvgRadioButton = ({ svgPath, isActive, onClick, w, h}) => {
//   return (
//     <SvgIcon onClick={onClick} active={isActive} svgPath={svgPath} w={w} h={h} />
//   );
// };

// const SvgRadioGroup = ({ onSelect, defaultSelectedIndex }) => {
//   const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);

//   const handleSelectAll = () => {
//     setSelectedIndex(5);
//     onSelect(5);
//   };

//   const handleSelectPython = () => {
//     setSelectedIndex(6);
//     onSelect(6);
//   };

//   const handleSelectGolang = () => {
//     setSelectedIndex(7);
//     onSelect(7);
//   };

//   return (
//       <HorizontalLayoutIcons>
//         <SvgRadioButton
//           key={7}
//           svgPath={'/go_logo.svg'}
//           isActive={selectedIndex === 7}
//           onClick={handleSelectGolang}
//           w={'30%'}
//           h={'20%'}
//         />
//         <SvgRadioButton
//           key={6}
//           svgPath={'/python_logo.svg'}
//           isActive={selectedIndex === 6}
//           onClick={handleSelectPython}
//           w={'15%'}
//           h={'20%'}
//         />
//         <SvgRadioButton
//           key={5}
//           svgPath={'/all.svg'}
//           isActive={selectedIndex === 5}
//           onClick={handleSelectAll}
//           w={'10%'}
//           h={'20%'}
//         />
//       </HorizontalLayoutIcons>
//   );
// };

const LogoContaner = styled.div`
  display: flex;
  justify-content: space-between;
  // align-items: center;
`;

const VLogoContaner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // border: 2px solid rgba(255, 255, 255, 0.8);
  margin-top: -35px;
  // align-items: center;
`;

const Header = styled.div`
  color: #fff;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
  
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: right;
  margin: 0;
`;

const Period = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
`;

const WhiteText = styled.p`
font-size: 1rem;
color: #fff;
`;

const jobVariants = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

const JobInfo = ({ job }) => {
  return (
    <TabContent
    variants={jobVariants}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{ duration: 0.5 }}
    highlight_color={job.highlight_color}
  >
      <Period>
     {job.period}
      </Period>
      <LogoContaner>
        <Header>
        {job.role}  <br/>
         {job.title} 
         </Header>
          <VLogoContaner>
        <img src={job.svgPath} alt={job.title} width="200" height="100" />
        </VLogoContaner>
      </LogoContaner>      
      <WhiteText><strong>Responsibilities:</strong> {job.responsibilities}</WhiteText>
      <WhiteText><strong>Main Achievement:</strong> {job.achievement}</WhiteText>
      <WhiteText><strong>Skills: </strong>
      {job.skills.map((skill, index) => (
        <SkillTag key={index} color={skillColors[skill]} >
          {skill}
        </SkillTag>
      ))}</WhiteText>
    </TabContent>
  );
};


const Experience = () => {
  const [selectedJob, setSelectedJob] = useState(5);

  useEffect(() => {
    setSelectedJob(5);
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <HorizontalLayout>
        {/* <SvgRadioGroup onSelect={setSelectedJob} defaultSelectedIndex={5} /> */}
        <Container>
          {selectedJob === 5 && companies.map((job, index) => (
            <JobInfo key={index} job={job} />
          ))}
          {selectedJob === 6 && companies
            .filter(job => job.skills.includes('Python'))
            .map((job, index) => (
              <JobInfo key={index} job={job} />
            ))}
          {selectedJob === 7 && companies
            .filter(job => job.skills.includes('Golang'))
            .map((job, index) => (
              <JobInfo key={index} job={job} />
            ))}
        </Container>
      </HorizontalLayout>
    </motion.div>
  );
};

export default Experience;
