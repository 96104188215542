import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';
import Info from './pages/Info';
import Experience from './pages/Experience';
import ThreeBackground from './components/ThreeBackground';
import MainLayout from './layouts/MainLayout';
import GlobalStyle from './globalStyles';

const App = () => {
  useEffect(() => {
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
      viewport.content = "width=device-width, initial-scale=1.0, maximum-scale=1.5, user-scalable=no";
    }
  }, []);

  return (
    <Router>
      <ThreeBackground />
      <GlobalStyle />
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/info" element={<Info />} />
          <Route path="/experience" element={<Experience />} />
        </Route>
      </Routes>
    </Router>
  );
};


export default App;
