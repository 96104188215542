import React, { useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, Outlet } from 'react-router-dom';

const MainContainer = styled.div`
  // position: relative;
  height: 100vh;
  width: 100%;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: center;
  color: #fff;

  overflow: hidden;
  // transform: scale(${props => props.scale});
  // transform-origin: top left;
  // transition: transform 0.2s ease;
`;

const HorizontalLayout = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  height: calc(100% - 100px);
  justify-content: space-between;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const Title = styled.h1`
  align-self: flex-start;
  font-size: 3rem;
  margin: 0;
  color: #fff;
  margin-left: 50px;
`;

const SubTitle = styled.h2`
  font-size: 2rem;
  margin: 0;
  margin-top: 0px;
  color: #fff;
`;

const Frame = styled.div`
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  padding: 20px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;

const Menu = styled.nav`
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;

    &.active::after {
      content: '•';
      margin-left: 10px;
      color: #ffffff;
    }
  }
`;

const HorizontalSocialLayout = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const TelegramIcon = styled.a`
  width: 40px;
  height: 40px;
  background-image: url('/tg_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
`;

const LinkedInIcon = styled.a`
  width: 40px;
  height: 40px;
  background-image: url('/linked_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
`;

const GmailIcon = styled.a`
  width: 40px;
  height: 40px;
  background-image: url('/gmail_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
`;

const CVDownload = styled.a`
  width: 77px;
  height: 40px;
  background-image: url('/cv_logo.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;

  &:hover {
    opacity: 0.9;
  }
`;

const MainLayout = ({ children }) => {

  useEffect(() => {
    const handleResize = (event) => {
      event.preventDefault();
      window.resizeTo(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <MainContainer>
      <Title>PARAMONOV ALEKSEI</Title>
      <Frame>
        <SubTitle>Senior Backend Engineer</SubTitle>
        <HorizontalLayout>
          <Menu>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} end>Home</NavLink>
            <NavLink to="/info" className={({ isActive }) => (isActive ? 'active' : '')}>About</NavLink>
            <NavLink to="/experience" className={({ isActive }) => (isActive ? 'active' : '')}>Experience</NavLink>
          </Menu>
            <Outlet />
        </HorizontalLayout>
        <HorizontalSocialLayout>
          <TelegramIcon href="https://t.me/Deda_Rick" target="_blank" rel="noopener noreferrer" />
          <LinkedInIcon href="https://www.linkedin.com/in/paramonovdotdev" target="_blank" rel="noopener noreferrer" />
          <GmailIcon href="mailto:alxey96@gmail.com" target="_blank" rel="noopener noreferrer" />
          <CVDownload href="/Paramonov_CV_2024.pdf" download />
        </HorizontalSocialLayout>
      </Frame>
    </MainContainer>
  );
};

export default MainLayout;
