import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageVariants, pageTransition } from '../animations/animations';

const myPhoto = '/my_photo.jpg'; 

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  // align-items: flex-start;
  // width: 100%;
  height: calc(100%);
  gap: 30px;
  // padding: 20px;
  // box-sizing: border-box;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const MainHorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 0 1 70%;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;


const TextContainer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: right;
  justify-content: right;
  // width: 40%;
  flex: 0 1 50%;
  color: white;
  // border: 2px solid rgba(255, 255, 255, 0.8);
  height: 100%;
  padding-right: 20px;

  overflow-y: auto;
  overflow-x: hidden;

// Customize the scrollbar
  &::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
    height: 8px; /* Adjust height as needed */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px; /* Make it rounded */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: 30px; /* Minimum height for the vertical scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px; /* Minimum width for the horizontal scrollbar thumb */
  }
`;

const BaseText = styled.p`
  text-align: justify;
  font-size: 1rem;
`;


const HorizontalContainer = styled.div`
margin-top: 10px;
  width: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  // gap: 10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const UniversitySVG = styled.a`
// border: 2px solid rgba(255, 255, 255, 0.8);
min-width: 100px;  // specify the width
min-height: 100px;  // specify the height
  background-image: url('/msu_icon.svg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
    
  &:hover {
    opacity: 1.0;
  }
`;

const EFPng = styled.a`
// set minimum width and height

min-width: 100px;  // specify the width
min-height: 100px;  // specify the height
// border: 2px solid rgba(255, 255, 255, 0.8);
  background-image: url('/ef.png');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.9;
    
  &:hover {
    opacity: 1.0;
  }
`;


const PhotoContainer = styled.div`
  display: flex;
  flex: 0 1 60%;
  height: 100%;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const Photo = styled.img.attrs({
  src: myPhoto,
  alt: 'My Photo',
})`
  // flex: 0 1 70%;
  width: 100%;
  height: auto;
  object-fit: contain; 
  
  border-radius: 5px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
`;

const Info = () => (
  <motion.div
    initial="initial"
    animate="in"
    exit="out"
    variants={pageVariants}
    transition={pageTransition}
  > 
    <InfoContainer>
      <MainHorizontalContainer>
      <TextContainer>
        {/* <h1>About Me</h1> */}
        <BaseText>I am a senior backend developer with extensive expertise in Golang and Python. Was born in Moscow in 1996. Based in Belgrade, I bring a wealth of experience and a high level of engineering proficiency to every project. </BaseText>
        <h2>Education</h2>
        <HorizontalContainer>
        <BaseText>Bachelor's degree in Geology from Moscow State University.</BaseText>
          <UniversitySVG href="https://msu.ru/en/info/" target="_blank" rel="noopener noreferrer"  /> 

        </HorizontalContainer>
        <HorizontalContainer>
        <BaseText> Languages: English Advanced Level. Russian Native.</BaseText>
          <EFPng href="https://cert.efset.org/JYS18T" target="_blank" rel="noopener noreferrer"  /> 
          
          </HorizontalContainer>
        <h2>Interests</h2>
        <BaseText>I'm a techno geek with a passion for exploring the latest in technology. As a coding enthusiast, I enjoy diving deep into new programming challenges and innovative solutions. My love for geology fuels my adventures as a traveler, always eager to discover new landscapes and natural wonders. One of my favorite writers is Isaac Asimov, whose visionary science fiction continues to inspire me. When I'm not immersed in tech or travel, you can find me enjoying metal music and indulging in good movies.</BaseText>
        {/* <h2>Hobbies</h2>
        <BaseText>Outside of my professional and technological pursuits, I have a range of hobbies that keep me active and entertained. I enjoy working out to stay fit and healthy, and playing the guitar to express my creative side. Walking is a simple pleasure that allows me to clear my mind, and videogames are a fun escape where I can immerse myself in different worlds and challenges.</BaseText> */}
      </TextContainer>
      <PhotoContainer>
        <Photo src={myPhoto} alt="My Photo" />
      </PhotoContainer>
      </MainHorizontalContainer>
    </InfoContainer>
  </motion.div>
);

export default Info;
