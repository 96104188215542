import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { pageVariants, pageTransition } from '../animations/animations';
import { useLocation } from 'react-router-dom';
import { useInterval } from 'react-use';
import { TagCloud } from 'react-tagcloud';
import '../styles/tagcloud.css';


const GlobalLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 100%;
  height: 100%;
  width: 100%;
  justify-content: right;
  // border: 2px solid rgba(255, 255, 255, 0.8);
  gap: 20px;
`;
const TextLayout = styled.div`
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow-x: hidden;
  max-width: ${props => props.maxWidth}px; // Dynamically set max-width

  // border: 2px solid rgba(255, 255, 255, 0.8);


  &::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
    height: 8px; /* Adjust height as needed */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px; /* Make it rounded */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.7);
  }

  &::-webkit-scrollbar-thumb:vertical {
    min-height: 30px; /* Minimum height for the vertical scrollbar thumb */
  }

  &::-webkit-scrollbar-thumb:horizontal {
    min-width: 30px; /* Minimum width for the horizontal scrollbar thumb */
  }

`;

// Styled components for fancy text design
const Layout = styled.div`
  // justify-content: space-between;
  // gap: 10px;
  display: flex;
  // margin-top: -10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
  max-height: ${props => props.maxHeight}px;
`;

const MeasureLayout = styled.div`
  // justify-content: space-between;
  flex: 0 1 auto;
  // width: 100%;
  gap: 10px;
  // margin-right: -20px;
  // margin: 0px 20px;
  display: flex;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const NarrowLayout = styled.div`
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;


const VerticalLayout = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const MeasureVerticalLayout = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // gap: -10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const TextBlock = styled.div`
  font-weight: bold;
  color: #ffffff;
  opacity: 0.9;
  &:hover {
    opacity: 1.0;
  }
`;

const LargeText = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 6rem;
  // transform:scale(1.0,1.2);
  // margin-top: -10px;
  // justify-content: ;
  // margin-top: -10px;
  
`;

const GolangLargeText = styled(LargeText)`
&:hover {
  color: #6be1ff;
  
}
`
const PythonLargeText = styled(LargeText)`
&:hover {
  color: #306998;
}
`

const LargeText2 = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 6rem;
  // transform:scale(1.5,1.5);
  // margin-top: -15px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const LargeText3 = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 5.4rem;
  // margin-top: -15px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;


const MediumText = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 4.5rem;
  margin-top: 5px;
  // margin-top: 0px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const MediumText2 = styled(TextBlock)`
  flex: 0 1 auto;
  font-size:2rem;
  text-transform: uppercase;
  margin-top: -10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const MediumText3 = styled(TextBlock)`
  flex: 0 1 auto;
  font-size:3.3rem;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const DHMSVerticalLayout = styled.div`
  margin-top: 15px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    min-width: 130px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const SmallText = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 1.2rem;
  text-transform: uppercase;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;

const SmallTextY = styled(TextBlock)`
  flex: 0 1 auto;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-right: 10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;


const HorLayout = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  // gap: 10px;
  // border: 2px solid rgba(255, 255, 255, 0.8);
`;


const FlexTagCloud = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

// Function to calculate time difference in various units
const calculateDuration = (startDate) => {
  const now = new Date();
  const start = new Date(startDate);
  const duration = now - start;

  const years = Math.floor(duration / (1000 * 60 * 60 * 24 * 365));
  const months = Math.floor((duration % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor((duration % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
  const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((duration % (1000 * 60)) / 1000);

  return { years, months, days, hours, minutes, seconds };
};

const Home = (color) => {
  const location = useLocation();
  const secondLayoutRef = useRef(null);
  const firstLineMaxHeightRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState(null);
  const [maxHeight, setMaxHeight] = useState(null);
  const [pythonDuration, setPythonDuration] = useState(calculateDuration('2019-08-08'));
  const [golangDuration, setGolangDuration] = useState(calculateDuration('2023-01-27'));

  useInterval(() => {
    setPythonDuration(calculateDuration('2019-08-08'));
    setGolangDuration(calculateDuration('2023-01-27'));
  }, 1000);

  useEffect(() => {
    if (secondLayoutRef.current) {
      const { offsetWidth } = secondLayoutRef.current;
      setMaxWidth(offsetWidth);
    }
  }, [secondLayoutRef]);


  useEffect(() => {
    if (firstLineMaxHeightRef.current) {
      const { offsetHeight } = firstLineMaxHeightRef.current;
      setMaxHeight(offsetHeight);
    }
  }, [firstLineMaxHeightRef]);

  return (
    <motion.div
      key={location.pathname}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    > 
      <GlobalLayout>
      <TextLayout maxWidth={maxWidth}>
      <Layout maxHeight={maxHeight}>
      <MeasureLayout ref={secondLayoutRef}> 
        {/* <VerticalText>  Commertial </VerticalText> */}
        <MeasureVerticalLayout ref={firstLineMaxHeightRef}>
          <PythonLargeText>PYTHON</PythonLargeText>
          <MediumText2>Developer for</MediumText2>
        </MeasureVerticalLayout>
        <VerticalLayout>
          <LargeText2>{pythonDuration.years}</LargeText2>
          <SmallTextY>Years</SmallTextY>
        </VerticalLayout>
        <VerticalLayout>

          <MediumText>{pythonDuration.months}</MediumText>
          <SmallTextY>Months</SmallTextY>
        </VerticalLayout>
        <DHMSVerticalLayout>
          <HorLayout><SmallText>{pythonDuration.days}</SmallText><SmallText>days</SmallText></HorLayout>
          <HorLayout><SmallText>{pythonDuration.hours}</SmallText><SmallText>hours</SmallText></HorLayout>
          <HorLayout><SmallText>{pythonDuration.minutes}</SmallText><SmallText>minutes</SmallText></HorLayout>
          <HorLayout><SmallText>{pythonDuration.seconds}</SmallText><SmallText>seconds</SmallText></HorLayout>
        </DHMSVerticalLayout>
        </MeasureLayout>
      </Layout>
    <Layout maxHeight={maxHeight}>

    <MeasureVerticalLayout>
    <GolangLargeText>GOLANG</GolangLargeText>
    <MediumText2>EGINEERING FOR</MediumText2>
    </MeasureVerticalLayout>
    <VerticalLayout ref={firstLineMaxHeightRef}>
          <LargeText2>{golangDuration.years}</LargeText2>
          <SmallTextY>Years</SmallTextY>
        </VerticalLayout>
        <VerticalLayout>
          
          <MediumText>{golangDuration.months}</MediumText>
          <SmallTextY>Months</SmallTextY>
        </VerticalLayout>
    <DHMSVerticalLayout >
          <HorLayout><SmallText>{golangDuration.days}</SmallText><SmallText>days</SmallText></HorLayout>
          <HorLayout><SmallText>{golangDuration.hours}</SmallText><SmallText>hours</SmallText></HorLayout>
          <HorLayout><SmallText>{golangDuration.minutes}</SmallText><SmallText>minutes</SmallText></HorLayout>
          <HorLayout><SmallText>{golangDuration.seconds}</SmallText><SmallText>seconds</SmallText></HorLayout>
        </DHMSVerticalLayout>

    </Layout>
    <NarrowLayout>
      <VerticalLayout>  
        <SmallText>now live</SmallText>
        <MediumText3>in:</MediumText3>
      </VerticalLayout>
    <LargeText3>BELGRAD</LargeText3>
    </NarrowLayout>
    <FlexTagCloud>
    <TagCloud
      minSize={12}
      maxSize={30}
      tags={tags}
      colorOptions={{ luminosity: 'light', hue: '#ff7f4d'} }

/></FlexTagCloud>
</TextLayout>
        </GlobalLayout>
    </motion.div>
  );
};

export default Home;
// #007f7f
// #ff7f4d

// const colors = ['#FA8072', '#E9967A', '#FFA07A', '#DC143C', '#FF0000', '#B22222', '#8B0000'];  // Replace with your colors

// function getRandomColor() {
//   const randomIndex = Math.floor(Math.random() * colors.length);
//   return colors[randomIndex];
// }

const tags = [
  { value: 'JavaScript', count: 25 },
  { value: 'React', count: 15 },
  { value: 'MongoDB', count: 8 },
  { value: 'Python', count: 30 },
  { value: 'Go', count: 25 },
  { value: 'Kafka', count: 20 },
  { value: 'PostgreSQL', count: 15 },
  { value: 'Scrum', count: 10 },
  { value: 'Kanban', count: 10 },
  { value: 'Docker', count: 20 },
  { value: 'Kubernetes', count: 20 },
  { value: 'Linux', count: 20 },
  { value: 'ShotGrid', count: 10 },
  { value: 'Rendering', count: 10 },
  { value: 'Git', count: 20 },
  { value: 'GitHub', count: 20 },
  { value: 'Agile', count: 15 },
  { value: 'DevOps', count: 15 },
  { value: 'AWS', count: 15 },
  { value: 'REST', count: 15 },
  { value: 'GraphQL', count: 15 },
  { value: 'Microservices', count: 15 },
  { value: 'SQL', count: 15 },
  { value: 'NoSQL', count: 15 },
  { value: 'CI/CD', count: 15 },
  { value: 'TDD', count: 15 },
  { value: 'EDD', count: 15 },
  { value: 'Figma', count: 15 },
    { value: 'System Design', count: 30 },
    { value: 'SAGA', count: 25 },
    { value: '2PC', count: 25 },
    { value: 'Clean Architecture', count: 25 },
    { value: 'DDD', count: 25 },
    { value: 'gRPC', count: 20 },
    { value: 'HTTP', count: 20 },
    { value: 'REST', count: 20 },
    { value: 'WebSockets', count: 15 },
    { value: 'OAuth', count: 15 },
    { value: 'JWT', count: 15 },
    { value: 'Microservices', count: 15 },
    { value: 'Load Balancing', count: 15 },
    { value: 'Caching', count: 15 },
    { value: 'Data Replication', count: 15 },
    { value: 'Data Sharding', count: 15 },
    { value: 'CAP Theorem', count: 15 },
    { value: 'Serverless Architecture', count: 15 },
    { value: 'API Design', count: 15 },
    { value: 'Data Structures', count: 15 },
    { value: 'Algorithms', count: 15 },
    { value: 'Network Protocols', count: 15 },
    { value: 'Concurrency', count: 15 },
    { value: 'Multithreading', count: 15 },
    { value: 'Rate Limiting', count: 15 },
    { value: 'Logging', count: 15 },
    { value: 'Monitoring', count: 15 },
    { value: 'Testing', count: 15 },
    { value: 'Metrics', count: 15 },
    { value: 'Prometheus', count: 15 },
    { value: 'Grafana', count: 15 },
    { value: 'Loki', count: 15 },
    { value: 'VictoriaMetrics', count: 15 },
    { value: 'S3', count: 15 },
    { value: 'nfs', count: 15 },
];
