import React, { useRef, useEffect } from 'react';
import {
  WebGLRenderer,
  Scene,
  OrthographicCamera,
  PlaneGeometry,
  Mesh,
  ShaderMaterial,
  Vector2,
  Clock
} from 'three';

function ThreeBackground() {
  const mountRef = useRef(null);

  useEffect(() => {
    const currentMount = mountRef.current;
    const scene = new Scene();
    const camera = new OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const renderer = new WebGLRenderer({ antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    currentMount.appendChild(renderer.domElement);

    const uniforms = {
      u_time: { value: 0.0 },
      u_resolution: { value: new Vector2(window.innerWidth, window.innerHeight) },
      u_mouse: { value: new Vector2() }
    };

    const onMouseMove = (event) => {
      uniforms.u_mouse.value.x = (event.clientX / window.innerWidth) * 2 - 1;
      uniforms.u_mouse.value.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };
    window.addEventListener('mousemove', onMouseMove);

    const material = new ShaderMaterial({
      uniforms: uniforms,
      vertexShader: `void main() { gl_Position = vec4(position, 1.0); }`,
      fragmentShader: `
      #ifdef GL_ES
      precision mediump float;
      #endif
      
      uniform vec2 u_resolution;
      uniform vec2 u_mouse;
      uniform float u_time;
      
      float random(in vec2 _st) {
          return fract(sin(dot(_st.xy, vec2(12.9898,78.233))) * 43758.5453123);
      }
      
      float noise(in vec2 _st) {
          vec2 i = floor(_st);
          vec2 f = fract(_st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (3.0 - 2.0 * f);
          return mix(a, b, u.x) + (c - a) * u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
      }
      
      #define NUM_OCTAVES 5
      
      float fbm(in vec2 _st) {
          float v = 0.0;
          float a = 0.5;
          vec2 shift = vec2(100.0);
          mat2 rot = mat2(cos(0.5), sin(0.5), -sin(0.5), cos(0.50));
          for (int i = 0; i < NUM_OCTAVES; ++i) {
              v += a * noise(_st);
              _st = rot * _st * 2.0 + shift;
              a *= 0.5;
          }
          return v;
      }
      
      void main() {
          vec2 st = gl_FragCoord.xy / u_resolution.xy * 5.; // Adjusted scale for larger plane
      
          vec3 color = vec3(0.0);
          vec2 q = vec2(0.);
          float scaledTime = u_time * 0.9; // Slowed time effect for a lazy movement
          q.x = fbm(st + 0.00 * scaledTime);
          q.y = fbm(st + vec2(1.0));
      
          vec2 r = vec2(0.);
          r.x = fbm(st + 1.0 * q + vec2(1.7, 9.2) + 0.15 * scaledTime);
          r.y = fbm(st + 1.0 * q + vec2(8.3, 2.8) + 0.126 * scaledTime);
      
          float f = fbm(st + r);
          // float shadow = fbm(st + r) * 0.3;

          vec3 softPeach = vec3(0.1, 0.1, 0.1);
          vec3 greenColor = vec3(0.3, 0.3, 0.3);

          color = mix(greenColor, softPeach, clamp(f, 0.0, 1.0));
          // color = mix(color, vec3(1.0, 0.1, 0.5), shadow);

          gl_FragColor = vec4(color, 1.0);
      }      
      `
    });

    const plane = new PlaneGeometry(2, 2);
    const mesh = new Mesh(plane, material);
    scene.add(mesh);

    const clock = new Clock();

    function animate() {
      requestAnimationFrame(animate);
      uniforms.u_time.value = clock.getElapsedTime();
      renderer.render(scene, camera);
    }

    animate();

    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      uniforms.u_resolution.value.set(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      currentMount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />;
}

export default ThreeBackground;
