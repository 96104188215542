const pageVariants = {
  initial: {
    opacity: 0,
    x: "-10vw"
  },
  in: {
    opacity: 1,
    x: 0
  },
  out: {
    opacity: 0,
    x: "10vw"
  }
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1.0
};

export { pageVariants, pageTransition };